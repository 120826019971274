import produce from "immer";

const INITIAL_STATE = { cert: ''};

const users = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {

    switch (action.type) {
      case "@certificates/FETCH_SERIAL_NUMBER":
        draft.cert = action.payload
        break;
      default:
        return state;
    }
  });
};

export default users;
