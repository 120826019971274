import produce from 'immer';

const INITIAL_STATE = {
  signed: false,
  userType: '',
  codeEvent: '',
  status: ''
};

const auth = (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case '@auth/AUTHENTICATE':
        draft.userType = action.payload.userType;
        draft.codeEvent = action.payload.events && action.payload.events.eventCode;
        draft.signed = true;
        break;
      case '@auth/AUTHENTICATE/CERTIFICATE':
        draft.userType = action.payload.userType;
        draft.codeEvent = action.payload.codeEvent;
        draft.signed = true;
        break;
      case '@auth/SING_OUT':
        draft.signed = false;
        break;
      case '@auth/FETCHING_DATA':
        draft.status = 'fetching';
        break;
      case '@auth/FETCHING_DATA_FINALLY':
        draft.status = 'ok';
        break;
      default:
    }
  });

export default auth;
