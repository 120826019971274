import axios from 'axios';
import { getToken } from "~/utils";
import { getDeviceClientData } from '~/utils/device';

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });
const apiCertificate = axios.create({ baseURL: process.env.REACT_APP_API_CERTIFICATE });

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    const userAgent = getDeviceClientData();

    if (token) config.headers.token = token;
    config.headers.client = userAgent;

    return config;
  },
  (error) => Promise.reject(error)
);

apiCertificate.interceptors.request.use(
  (config) => {
    const userAgent = getDeviceClientData();
    config.headers.client = userAgent;

    return config;
  },
  (error) => Promise.reject(error)
);

export const states = {
  select: () => api.get('states'),
};

export const cities = {
  select: (stateId) => api.get(`/states/${stateId}/cities`),
};

export const orders = {
  insert: (newOrder) => api.post(`/orders`, newOrder),
  get: (page) => api.get(`/orders`, { params: { page }}),
  getDetails: (id) => api.get(`orders/${id}`)
};

export const logs = {
  select: (page) => api.get(`/logs`, { params: { page }}),
};

export const companies = {
  selectRegistriesTypes: () => api.get(`/companies/registries/types`),
  insert: (company) => api.post('/companies', company),
  selectDetailsCadastralSituation: () => api.get('companies'),
  selectCnpj: (cnpj) => api.get(`/companies/cnpj/${cnpj}`),
  selectTypePackage: () => api.get('/companies/packages/types'),
  selectBrandCompany: (page) => api.get('/brands', { params: { page } }),
  selectAddressCompany: () => api.get('/addresses'),
  selectContactsCompany: () => api.get('/contacts'),
  selectFillingDetails: () => api.get('/companies'),
};

export const products = {
  selectProductsByCompany: (page) => api.get(`/products`, { params: { page } }),
  selectCurrentEad: (productId) => api.get(`/products/${productId}/calculate-ead`),
  insertProductCompany: (product) => api.post('/products', product),
};

export const contacts = {
  select: () => api.get(`/contacts/types`),
};

export const registries = {
  selectWatersTypes: (registriesId) => api.get(`/registries/${registriesId}/waters`),
};

export const users = {
  selectEmail: (email) => api.get(`/users/email/${email}`),
  insert: (user) => api.post('/users', user),
  insertUsersCertificate: (user) => api.post('/users/certificate', user),
};

export const auth = {
  login: data => api.post('auth', data),
  logout: () => api.delete('auth'),
};

export const notifications = {
  insertGraphicDelivery: data => api.post('/notifications/graphic-delivery', data),
  insertGraphicMisplacement: data => api.post('/notifications/graphic-misplacement', data),
  insertFillingUse: data => api.post('/notifications/filling-use', data),
  insertFillingMisplacement: data => api.post('/notifications/filling-misplacement', data),
  insertFillingDestruction: data => api.post('/notifications/filling-destruction', data),
};

export const seal = {
  select: data => api.get('/seals', {params: data}),
  selectHistory: orderId => api.get(`/seals/history/${orderId}`)
};

export default api;
