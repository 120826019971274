import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import Button from '~/components/Button';
import Input from "~/components/Input";
import Select from '~/components/Select';
import StepperBar from '~/components/StepperBar';
import { CERTIFICATE, ERROR_CNPJ_ALREADY_EXISTS, ORIENTATION_HORIZONTAL, PATTERN_CNPJ, PATTERN_ZIP_CODE, STEPPES_REGISTER } from '~/config/constants';
import history from '~/services/history';
import { fetchAddress, fetchCities, fetchStates } from '~/store/modules/addresses/actions';
import { clearFormData, fetchCnpj, sendFormData } from '~/store/modules/companies/actions';
import { cnpjMask, zipCodeMask } from '~/utils/mask';
import { registrationAddressFormSchema, registrationFormSchema } from '~/utils/validation';
import { FlexBox, Form, InformBox, StepperBox } from '../styles';
import { Container } from './styles';

function Address() {
  const [selectType, setSelectType] = useState('integrate');
  const [stateIsNotSaoPaulo, setStateIsSaoPaulo] = useState(false);
  const [state, setState] = useState({});
  const [city, setCity] = useState({});

  const dispatch = useDispatch();

  const { address,states, cities } = useSelector(stateAddresses => stateAddresses.addresses);
  const { formData, responseCnpj } = useSelector(stateCompany => stateCompany.companies);

  const formValidation = selectType === 'integrate'? registrationFormSchema : registrationAddressFormSchema;
  
  const { register, handleSubmit, errors, reset, getValues } = useForm({
    resolver: yupResolver(formValidation)
  });

  const handleCities = async stateId => {
    setState(stateId);
    dispatch(fetchCities(stateId));
  }

  useEffect(() => {
    const form = getValues();
    
    if(formData?.address){
      reset({
        ...formData,
        address:{  
          ...formData.address,
          zipCode: form.address?.zipCode,
          neighborhood: address.bairro,
          street: address.logradouro,
          complement: address.complemento,
        }})
    }else{
      reset({
        ...form,
        address:{  
          ...form?.address,
          zipCode: form.address?.zipCode,
          neighborhood: address.bairro,
          street: address.logradouro,
          complement: address.complemento,
        }})
    }

    setState(address.uf);
    setCity(address.localidade);  
    
    if(address.uf){
      handleCities(address.uf)
    }

    if(form?.address.zipCode && form?.address.zipCode.match(PATTERN_ZIP_CODE)){
      setStateIsSaoPaulo(address?.uf !== 'SP');
    }

    if(address.uf === 'SP'){
      setSelectType('integrate')
    }
  }, [address]);
  
  const onSubmit = (data) => {
    const integrationSefaz = selectType === 'integrate';  
    data.integrationSefaz = integrationSefaz;
    data.selectType = selectType;
  
    dispatch(sendFormData(data));
    history.push('/cadastro/registro');
  };

  const handleChangeAddress = async zipCode => {
    if (zipCode.match(PATTERN_ZIP_CODE)) {
      dispatch(fetchAddress(zipCode));
    }
  };

  const handleChangeCnpj = async cnpj => {
    if (cnpj.match(PATTERN_CNPJ)) {
      const cnpjFormatted = cnpj.replace('/', '%2F');
      dispatch(fetchCnpj(cnpjFormatted));
    }
  };

  const handleCity = event => {
    setCity(event.target.value);
  };

  const twoCalls = event => {
    handleChangeAddress(event.target.value);
    event.target.value = zipCodeMask(event);
  };

  const twoCallsCnpj = event => {
    handleChangeCnpj(event.target.value);
    event.target.value = cnpjMask(event);
  };

  const backPage = () => {
    if(CERTIFICATE.LOGIN_WITH_CERTIFICATE){
      history.push('/login/certificado')
    }else{
      history.push('/login');
    }
    dispatch(clearFormData());
  };

  const handleChangeRadio = event => setSelectType(event.target.value);

  useEffect(() => {
    if(formData?.cnpj){
      reset({...formData});
      setSelectType(formData.selectType)
    }
  }, []);

  useEffect(() => {
    const form = getValues();

    if(selectType === 'no-integrate'){
      dispatch(fetchStates());
      handleChangeAddress(form?.address?.zipCode);
    }
  }, [selectType]);

  return (
    <Container>
      <h1>Cadastro</h1>
      <StepperBox>
        <StepperBar
          steppes={STEPPES_REGISTER}
          activeStep={0}
          orientation={ORIENTATION_HORIZONTAL}
          className="container-step"
        />
      </StepperBox>
      
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FlexBox>
          <Input.Base 
            label="CNPJ"
            name="cnpj"
            type="text"
            ref={register}
            errors={errors.cnpj}
            maxLength="18"
            onChange={twoCallsCnpj}
          />

          <Input.Base 
            label="CEP"
            name="address.zipCode"
            type="text"
            ref={register}
            errors={errors.address?.zipCode}
            maxLength="9"
            onChange={twoCalls}
          />
        </FlexBox>

        {responseCnpj && <p>{ERROR_CNPJ_ALREADY_EXISTS}</p>}

        <Input.Base 
          label="Razão social"
          name="name"
          type="text"
          ref={register}
          errors={errors.name}
          maxLength={50}
        />
          
        <Input.Base 
          label="Nome fantasia"
          name="fantasyName"
          type="text"
          ref={register}
          errors={errors.fantasyName}
          maxLength={50}
        />
       
        {stateIsNotSaoPaulo && (
          <InformBox>
       
            <h3>Utilizar cadastramento integrado ao CADESP</h3>
            <p>
              Obrigatório para Envasadoras dentro do Estado de São Paulo. 
              Envasadoras fora do Estado de São Paulo, não exercer esta opção somente em claro caráter de exceção
            </p>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue={selectType}
              >
           
                <FormControlLabel
                  value='integrate'
                  control={(
                    <Radio
                      required
                      color="primary"
                    />
                    )}
                  label='Integrar'
                  onChange={handleChangeRadio}
                />
                <FormControlLabel
                  value='no-integrate'
                  control={(
                    <Radio
                      required
                      color="primary"
                    />
                    )}
                  label='Não integrar'
                  onChange={handleChangeRadio}
                />
              </RadioGroup>
            </FormControl>
    
          </InformBox>

        )}
        
        {selectType === 'no-integrate' && stateIsNotSaoPaulo && (
        <>
          <FlexBox>
            <Select.Base
              label="Estado"
              name="address.state"
              type="text"
              data={state}
              ref={register}
              options={states}
              errors={errors.address?.state}
              onChange={event => handleCities(event.target.value)}
            />

            <Select.Base
              label="Município"
              name="address.city"
              type="text"
              data={city}
              options={cities}
              ref={register}
              onChange={handleCity}
              errors={errors.address?.city}
            />
          </FlexBox>

          <FlexBox>
            <Input.Base 
              label="Bairro"
              name="address.neighborhood"
              type="text"
              ref={register}
              errors={errors.address?.neighborhood}
              maxLength={50}
            />
              
            <Input.Base 
              label="Complemento"
              name="address.complement"
              type="text"
              ref={register}
              errors={errors.address?.complement}
              maxLength={50}
            />
          </FlexBox>

          <FlexBox>
            <Input.Base 
              label="Logradouro"
              name="address.street"
              type="text"
              ref={register}
              errors={errors.address?.street}
              maxLength={50}
              className="street"
            />
              
            <Input.Base 
              label="Número"
              name="address.number"
              type="text"
              ref={register}
              errors={errors.address?.number}
              maxLength={6}
              className="number"
            />
          </FlexBox>
        </>
        )}
        <FlexBox>
          <Button.Outlined onClick={backPage}>
            Voltar
          </Button.Outlined>
          <Button.Rounded type="submit">
            Avançar
          </Button.Rounded>   
        </FlexBox>
      </Form>      
    </Container>
  );
}

export default Address;
