import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import Button from '~/components/Button';
import CheckBox from '~/components/CheckBox';
import Input from "~/components/Input";
import StepperBar from '~/components/StepperBar';
import { ERROR_EMAIL_ALREADY_EXISTS, ORIENTATION_HORIZONTAL, PATTERN_EMAIL, STEPPES_REGISTER } from '~/config/constants';
import history from '~/services/history';
import { fetchHashCertificate, sendCertificate } from '~/store/modules/certificates/actions';
import { sendFormData } from '~/store/modules/companies/actions';
import { createUser, createUserCertificate, fetchEmail } from '~/store/modules/users/actions';
import { getQueryParam } from '~/utils';
import { registrationLoginCertificateFormSchema, registrationLoginFormSchema } from '~/utils/validation';
import { FlexBox, Form, StepperBox } from '../styles';
import { Container } from './styles';
import Success from './Success';

function Login() {
  const dispatch = useDispatch();

  const { formData, status } = useSelector(state => state.companies);
  const { responseEmail } = useSelector(state => state.users);
  const { cert } = useSelector(state => state.certificates);

  const [certificateIsEmpty, setCertificateIsEmpty ] = useState(false);
  const certificateEnabled = JSON.parse(process.env.REACT_APP_LOGIN_WITH_CERTIFICATE);
  const validation = certificateEnabled ? registrationLoginCertificateFormSchema : registrationLoginFormSchema;
    
  const { register, handleSubmit, errors, reset, getValues } = useForm({
    resolver: yupResolver(validation)
  });
  const   onSubmit = ({user}) => {
    const company = {...formData, user, cert}; 

    if(cert === ''){
      setCertificateIsEmpty(true);
    }else{
      setCertificateIsEmpty(false);
    }

    if(!certificateEnabled){
      dispatch(createUser(company));
    }

    if(certificateEnabled && cert !== ''){
      dispatch(createUserCertificate(company));
    }
  };

  const backPage = () => {
    history.push('/cadastro/marca');
    dispatch(sendFormData(getValues()));
  };

  const handleChangeEmail = async email => {
    if (email.match(PATTERN_EMAIL)) {
      dispatch(fetchEmail(email));
    }
  };

  useEffect(() => {
    if(formData?.user?.name){
      reset({...formData});
    }
  }, []);

  useEffect(() => {
    const certificate = getQueryParam('cert');
     if(certificate){
       dispatch(sendCertificate(certificate));
     }
   }, [])

  const checkIfTermsAreAccepted = (errors.user?.term?.first || errors.user?.term?.second || errors.user?.term?.third || errors.user?.term?.fourth);

  return (
    <Container>
      <h1>Cadastro</h1>
      <StepperBox>
        <StepperBar
          steppes={STEPPES_REGISTER}
          activeStep={4}
          orientation={ORIENTATION_HORIZONTAL}
          className="container-step"
        />
      </StepperBox>
      
      <Form onSubmit={handleSubmit(onSubmit)}>
        {certificateIsEmpty && <p>Por favor, selecione um certificado</p>}
        {certificateEnabled && (
        <>
          <b>Clique no botão para adicionar o certificado digital</b>
          <div className="image" alt="certificado digital" onClick={() => dispatch(fetchHashCertificate())} />   
        </>
          )}

        <Input.Base 
          label="Nome"
          name="user.name"
          type="text"
          ref={register}
          errors={errors.user?.name}
          maxLength={50}
        />

        {!certificateEnabled && (
          <>
            <Input.Base 
              label="Email"
              name="user.email"
              type="email"
              ref={register}
              errors={errors.user?.email}
              maxLength={50}
              onBlur={event => handleChangeEmail(event.target.value)}
            />

            {responseEmail && <p>{ERROR_EMAIL_ALREADY_EXISTS}</p>}
                      
            <Input.Base 
              label="Senha"
              name="user.password"
              type="password"
              ref={register}
              maxLength={64}
              errors={errors.user?.password}
            />

            <Input.Base 
              label="Confirmação de Senha"
              name="user.repeatPassword"
              type="password"
              ref={register}
              maxLength={64}
              errors={errors.user?.repeatPassword}
            />
          </>
        )}       
        

        <h3>Para se cadastrar no sistema é necessário concordar com os termos abaixo:</h3>

        {checkIfTermsAreAccepted && <p>Todos os termos são obrigatórios</p>}
        <CheckBox.Base name="user.term.first" ref={register}>
          Concordo com o conteúdo da seção CREDENCIAMENTO DOS
          ESTABELECIMENTOS ENVASADORES DE ÁGUA MINERAL
          (ENVASADORAS) de que trata a Portaria CAT 85/2020, inclusive
          das implicações constantes do não cumprimento, disposto
          visualmente.
        </CheckBox.Base>
        
        <CheckBox.Base name="user.term.second" ref={register}>
          Concordo com a necessidade de, no caso de contribuinte integrado ao
          CADESP, de que este esteja em situação cadastral “Ativo”, e
          contemplando, em sua relação de CNAEs (Classificação Nacional
          de Atividades Econômicas), o “11.21-6/00 – Fabricação de Águas
          Envasadas”, seja como CNAE Principal, seja constando na
          relação de CNAEs secundários.
        </CheckBox.Base>
        <CheckBox.Base name="user.term.third" ref={register}>
          Concordo com a obrigatoriedade de cadastramento posterior de todos os
          produtos retornáveis de volume superior a 4 litros das marcas
          credenciadas.
        </CheckBox.Base>
        <CheckBox.Base name="user.term.fourth" ref={register}>
          Aceito o manifesto de que a não prestação adequada da informação da destinação dos selos caracteriza omissão, ensejando as penalidades previstas na legislação.
        </CheckBox.Base>
            
        <FlexBox>
          <Button.Outlined onClick={backPage}>
            Voltar
          </Button.Outlined>
          <Button.Rounded type="submit" disabled={status === 'fetching'} loading>
            Finalizar
          </Button.Rounded>   
        </FlexBox>
      </Form>    
      <Success />  
    </Container>
)}

export default Login;
