import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Container } from './styles';
import imgBgBtnCertificate from '~/assets/bg-btn-certificate.png';
import history from '~/services/history';
import Button from '~/components/Button';
import {
  authenticateWithCertificate,
  persistTokenCertificate,
} from '~/store/modules/auth/actions';
import { getQueryParam } from '~/utils';

function CertificateAccess() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { signed } = useSelector((state) => state.auth);

  const getTokenUrl = () => {
    const { search } = location;
  
    const queryParams = search.split('=');
    const param =  queryParams[1];
    const token = param && param.replace('&name', '');
  
    return token;
  };

  useMemo(() => {
    const token = getTokenUrl();
    const name = getQueryParam('name');
    const eventCode = getQueryParam('eventCode');
    const companyName = getQueryParam('companyName');
    const userType = getQueryParam('userType');

    const codeEvent = { name, eventCode };

    const payload = { token, codeEvent, companyName, userType };

    token && dispatch(() => persistTokenCertificate(payload, dispatch));
  }, []);

  useEffect(() => {
    if (signed) {
      history.push('/sistema/cadastro-sefaz/detalhes');
    }
  }, [signed]);

  const onSubmit = (event) => {
    event.preventDefault();

    dispatch(authenticateWithCertificate());
  };

  return (
    <Container>
      <form onSubmit={onSubmit}>
        <h1>Acesso via Certificado Digital</h1>

        <p>
          Se você já possui Certificado Digital, por favor, clique na imagem
          abaixo.
        </p>

        <input
          type="image"
          src={imgBgBtnCertificate}
          alt="certificado digital"
        />
      </form>
      <p>Não possui um login?</p>
      <Button.Outlined onClick={() => history.push('/cadastro')}>
        Registrar-se
      </Button.Outlined>
    </Container>
  );
}

export default CertificateAccess;
