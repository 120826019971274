import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {v1 as id} from "uuid"; 
import image from '~/assets/photo.png';
import Button from '~/components/Button';
import Input from "~/components/Input";
import Select from '~/components/Select';
import StepperBar from '~/components/StepperBar';
import { ERROR_FILE_SIZE, ERROR_FILE_TYPE, ORIENTATION_HORIZONTAL, STEPPES_REGISTER, SUPPORTED_FORMATS } from '~/config/constants';
import history from '~/services/history';
import { sendFormData } from "~/store/modules/companies/actions";
import { fetchWaterTypes } from "~/store/modules/registries/actions";
import { registrationBrandFormSchema } from "~/utils/validation";
import { FlexBox, StepperBox } from '../styles';
import { Container, FormBrand } from './styles';
import { checkIfFileAreCorrectType, checkIfFileAreTooBig, compressedFile } from "~/utils";

function Brand() {
  const initialStateForm = {id: id(), name: '', waterType:'', logo: '', image: '', error: ''};

  const [waterType, setWaterType] = useState({});
  const [fields, setFields] = useState([initialStateForm]);
  
  const { waterTypes } = useSelector(state => state.registries);
  const { formData } = useSelector(state => state.companies);

  const dispatch = useDispatch();

  const { register,  handleSubmit, errors, getValues} = useForm({
    resolver: yupResolver(registrationBrandFormSchema),
    mode:"onChange"
  });

  const append = () => {
    const array = [...fields, {...initialStateForm, id: id()}];
    setFields(array);
  };

  const remove = (index) => {
    setFields([...fields.slice(0, index), ...fields.slice(index + 1)]);
  };

  const onSubmit = () => {
    let imageEmpty = 0;
    const form = getValues();
    
     fields.forEach((brand, index) => {
          brand.logo === ''? imageEmpty =+ 1: imageEmpty =- 1;
          brand.name = form.brands[index].name;
          brand.waterType = form.brands[index].waterType;
    
          if(imageEmpty > 0){
            fields[index].error = 'Foto da marca é obrigatória';
          }else{
            fields[index].error = '';
          }
        }); 

    if(!imageEmpty.length){
        dispatch(sendFormData({brands: fields}));
    
        history.push('/cadastro/login');
    }
  };
 
  const handleChangeImagePreview = async (event, index) => {
    const array = [...fields];

    const reader = new FileReader();
    const file =  event.target.files[0];

    const fileCompressed = await compressedFile(file);
    const fileAreTooBig = checkIfFileAreTooBig(fileCompressed);
    const fileAreCorrectType = checkIfFileAreCorrectType(fileCompressed);

    if(!fileAreTooBig){
      array[index].error = ERROR_FILE_SIZE;
    }

    if(!fileAreCorrectType){
      array[index].error = ERROR_FILE_TYPE;
    }

    if(fileAreCorrectType && fileAreTooBig){
      if (fileCompressed) reader.readAsDataURL(fileCompressed);
      const result = await new Promise((resolve) => {
        reader.onload = function() {
          resolve(reader.result);
        }
      })
      
     array[index].logo = result;
     array[index].error = '';
    }
    
    setFields([...array]);
  };

  const handleWaterTypes = async waterTypeId => {
    setWaterType(waterTypeId);
  }

  useEffect(() => {
    dispatch(fetchWaterTypes(formData.registerType));

    if(formData?.brands && formData?.brands[0]){
      formData.brands.forEach((brand, index) => {
        fields[index] = {...brand};       
      }); 
    }
  }, []);

  return (
    <Container>
      <h1>Cadastro</h1>
      <StepperBox>
        <StepperBar
          steppes={STEPPES_REGISTER}
          activeStep={3}
          orientation={ORIENTATION_HORIZONTAL}
          className="container-step"
        />
      </StepperBox>

      <h2>Permitido apenas 10 marcas</h2>
      <h2>
        {fields.length}
        /10
      </h2>
      
      <FormBrand onSubmit={handleSubmit(onSubmit)}>
        {fields.map((item, index) => (
          <span key={item.id}>
            {fields.length > 1 && (<MdClose onClick={() => remove(index)} />)}
            <h3>Selecione uma foto de sua marca</h3>
  
            <label htmlFor={item.id}>
              <img
                src={item.logo !== ''? item.logo : image}
                alt="Selecione uma imagem"
                className="upload-icon"
              />
              <input
                name={`brands[${index}].image`}
                type="file"
                accept={SUPPORTED_FORMATS}
                ref={register}
                onChange={e => handleChangeImagePreview(e, index)}
              />
            </label>
            <p>{fields[index]?.error}</p>
        
            <Input.Base 
              label="Nome da Marca"
              name={`brands[${index}].name`}
              type="text"
              ref={register}
              defaultValue={item?.name}
              errors={errors.brands &&
                errors.brands[index] &&
                errors.brands[index].name}
              maxLength={50}
            />        
        
            <Select.Base
              label="Tipo de Água"
              name={`brands[${index}].waterType`}
              type="text"
              defaultValue={item?.waterType}
              data={waterType}
              ref={register}
              options={waterTypes}
              onChange={event => handleWaterTypes(event.target.value)}
              errors={errors.brands &&
                errors.brands[index] &&
                errors.brands[index].waterType}
            />
            <hr />
          </span>
      ))}

        {fields.length < 10 && (
        <span className="add" role="button" tabIndex={0} onClick={append}>Adicionar nova marca +</span>
      )}          
                
        <FlexBox className="container-button">
          <Button.Outlined onClick={() => history.push('/cadastro/contato')}>
            Voltar
          </Button.Outlined>
          <Button.Rounded type="submit">
            Avançar
          </Button.Rounded>   
        </FlexBox>
      </FormBrand>      
    </Container>
)}

export default Brand;
