import { CERTIFICATE } from '~/config/constants';
import { requestError } from '~/utils/errors';

export const fetchHashCertificate = () => async () => {
  try {
    window.open(
      `${CERTIFICATE.CERTIFICATE_AUTH_URL}?id=${CERTIFICATE.KEY_ID}&nome=${CERTIFICATE.KEY_NAME}&retorno=${CERTIFICATE.CERTIFICATE_REGISTER}`,
      '_self'
    );
  } catch (error) {
    requestError(error);
  }
};

export const sendCertificate = (cert) => async (dispatch) => {
  try {
    dispatch({ type: '@certificates/FETCH_SERIAL_NUMBER', payload: cert });
  } catch (error) {
    requestError(error);
  }
};
