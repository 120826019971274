import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import Button from '~/components/Button';
import Input from "~/components/Input";
import Select from '~/components/Select';
import StepperBar from '~/components/StepperBar';
import { ORIENTATION_HORIZONTAL, STEPPES_REGISTER } from '~/config/constants';
import history from '~/services/history';
import { fetchRegistriesTypes, sendFormData } from '~/store/modules/companies/actions';
import { registrationRegisterFormSchema } from '~/utils/validation';
import { FlexBox, Form, StepperBox } from '../styles';
import { Container } from './styles';

function Register() {

  const [registerType, setRegisterType] = useState({});

  const dispatch = useDispatch();

  const { registriesTypes, formData } = useSelector(state => state.companies);
    
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(registrationRegisterFormSchema)
  });
  const onSubmit = (data) => {
    dispatch(sendFormData(data));
    history.push('/cadastro/contato');
  };

  const handleRegistriesTypes = async registerTypeId => {
    setRegisterType(registerTypeId);
  }

  useEffect(() => {
    dispatch(fetchRegistriesTypes());

    if(formData?.registerType){
      reset({...formData});
    }
  }, []);

  return (
    <Container>
      <h1>Cadastro</h1>
      <StepperBox>
        <StepperBar
          steppes={STEPPES_REGISTER}
          activeStep={1}
          orientation={ORIENTATION_HORIZONTAL}
          className="container-step"
        />
      </StepperBox>
      
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Select.Base
          label="Tipo de Registro"
          name="registerType"
          type="text"
          data={registerType}
          ref={register}
          options={registriesTypes}
          errors={errors.registerType}
          onChange={event => handleRegistriesTypes(event.target.value)}
        />

        <FlexBox>
          <Input.Base 
            label="Número do Registro"
            name="registerNumber"
            type="text"
            ref={register}
            errors={errors.registerNumber}
            maxLength={9}
          />
              
          <Input.Base 
            label="Ano do Registro"
            name="registerYear"
            type="text"
            ref={register}
            errors={errors.registerYear}
            maxLength={4}
          />
        </FlexBox>

        <FlexBox>
          <Input.Base 
            label="Número de licença sanitária"
            name="sanitaryLicenseNumber"
            type="text"
            ref={register}
            errors={errors.sanitaryLicenseNumber}
            maxLength={9}
          />
              
          <Input.Base 
            label="Ano de licença sanitária"
            name="sanitaryLicenseYear"
            type="text"
            ref={register}
            errors={errors.sanitaryLicenseYear}
            maxLength={4}
          />
        </FlexBox>
        
        <FlexBox>
          <Input.Base 
            label="Número de licença ambiental"
            name="environmentalLicenseNumber"
            type="text"
            ref={register}
            errors={errors.environmentalLicenseNumber}
            maxLength={9}
          />
              
          <Input.Base 
            label="Ano de licença ambiental"
            name="environmentalLicenseYear"
            type="text"
            ref={register}
            errors={errors.environmentalLicenseYear}
            maxLength={4}
          />
        </FlexBox>
                
        <FlexBox>
          <Button.Outlined onClick={() => history.push('/cadastro')}>
            Voltar
          </Button.Outlined>
          <Button.Rounded type="submit">
            Avançar
          </Button.Rounded>   
        </FlexBox>
      </Form>      
    </Container>
)}

export default Register;
