import { users } from '~/services/api';
import { requestError } from '~/utils/errors';
import { createCompany } from '../companies/actions';

export const fetchEmail = (email) => async dispatch => {
  try {
    await users.selectEmail(email);

    dispatch({ type: '@users/FETCH_EMAIL', payload: true });
  } catch (error) {
    if (error.response.status === 404) {
      dispatch({ type: '@users/FETCH_EMAIL', payload: false });
    }else{
      requestError(error);
    }
  }
};

export const createUser = company => async dispatch => {
  dispatch({ type: '@companies/FETCHING_DATA' })
  try {
    const user = {...company.user, serialNumber: company.serialNumber};
    const { data } = await users.insert(user);

    dispatch(createCompany({...company, user: data.payload.idUser}))
  } catch (error) {
    requestError(error);
    dispatch({ type: '@companies/FETCHING_DATA_FINALLY' })
  }
};

export const createUserCertificate = company => async dispatch => {
  dispatch({ type: '@companies/FETCHING_DATA' })
  try {
    const user = {...company.user, cert: company.cert};
    const { data } = await users.insertUsersCertificate(user);


    dispatch(createCompany({...company, user: data.payload.idUser}))
  } catch (error) {
    requestError(error);
    dispatch({ type: '@companies/FETCHING_DATA_FINALLY' })
  }
};


