import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Button from '~/components/Button';
import Input from '~/components/Input';
import Select from '~/components/Select';
import StepperBar from '~/components/StepperBar';
import { ORIENTATION_HORIZONTAL, STEPPES_REGISTER } from '~/config/constants';
import history from '~/services/history';
import { sendFormData } from '~/store/modules/companies/actions';
import { fetchContactsTypes } from '~/store/modules/contacts/actions';
import { cellphoneMask, phoneMask } from '~/utils/mask';
import { registrationContactFormSchema } from '~/utils/validation';
import { FlexBox, Form, StepperBox } from '../styles';
import { Container } from './styles';

function Contact() {
  const [contactType, setContactType] = useState({});
  const [labelApp, setLabelApp] = useState("Nome do aplicativo (opcional)");

  const dispatch = useDispatch();

  const { contactsTypes } = useSelector((state) => state.contacts);
  const { formData } = useSelector((state) => state.companies);

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(registrationContactFormSchema),
  });
  const onSubmit = (data) => {
    dispatch(sendFormData(data));
    history.push('/cadastro/marca');
  };

  const handleContactsTypes = async (contactTypeId) => {
    setContactType(contactTypeId);
    contactTypeId === "1" ? setLabelApp("Nome do aplicativo") : setLabelApp("Nome do aplicativo (opcional)");
  };

  useEffect(() => {
    dispatch(fetchContactsTypes());

    if (formData?.contact?.contactType) {
      reset({ ...formData });
    }
  }, []);

  return (
    <Container>
      <h1>Cadastro</h1>
      <StepperBox>
        <StepperBar
          steppes={STEPPES_REGISTER}
          activeStep={2}
          orientation={ORIENTATION_HORIZONTAL}
          className="container-step"
        />
      </StepperBox>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Select.Base
          label="Tipo de contato"
          name="contact.contactType"
          type="text"
          data={contactType}
          ref={register}
          options={contactsTypes}
          errors={errors.contact?.contactType}
          onChange={(event) => handleContactsTypes(event.target.value)}
        />

        <Input.Base
          label="Nome do Contato"
          name="contact.name"
          type="text"
          ref={register}
          errors={errors.contact?.name}
          maxLength={50}
        />

        <Input.Base
          label={labelApp}
          name="contact.appName"
          type="text"
          ref={register}
          errors={errors.contact?.appName}
          maxLength={50}
        />
        <Input.Base
          label="Contato"
          name="contact.contact"
          type="text"
          ref={register}
          errors={errors.contact?.contact}
          maxLength={contactType !== '3' ? 13 : 80}
          onChange={(event) => {
            if (contactType !== '3') {
              event.target.value = event.target.value.replace('-', '');

              if (event.target.value.length > 11) {
                event.target.value = cellphoneMask(event);
              } else {
                event.target.value = phoneMask(event);
              }
            }
          }}
        />

        <FlexBox>
          <Button.Outlined onClick={() => history.push('/cadastro/registro')}>
            Voltar
          </Button.Outlined>
          <Button.Rounded type="submit">Avançar</Button.Rounded>
        </FlexBox>
      </Form>
    </Container>
  );
}

export default Contact;
