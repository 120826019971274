import { CERTIFICATE, TOKEN } from '~/config/constants';
import { auth } from '~/services/api';
import history from '../../../services/history';
import { requestError } from '~/utils/errors';

export const persistToken = (payload, dispatch) => {
  localStorage.setItem(TOKEN, payload.token);

  dispatch({ type: '@auth/AUTHENTICATE', payload });
};

export const persistTokenCertificate = (payload, dispatch) => {
  localStorage.setItem(TOKEN, payload.token);

  dispatch({ type: '@auth/AUTHENTICATE/CERTIFICATE', payload });
};

export const logout = () => async (dispatch) => {
  dispatch({ type: '@auth/SING_OUT' });

  try {
    await auth.logout();
    localStorage.clear();

    history.push('/');
  } catch (error) {
    requestError(error);
  }
};

export const authenticate = (credentials) => async (dispatch) => {
  dispatch({ type: '@auth/FETCHING_DATA' });

  try {
    const { data } = await auth.login(credentials);

    dispatch(() => persistToken(data.payload, dispatch));
  } catch (error) {
    requestError(error);
  } finally {
    dispatch({ type: '@auth/FETCHING_DATA_FINALLY' });
  }
};

export const authenticateWithCertificate = () => async (dispatch) => {
  dispatch({ type: '@auth/FETCHING_DATA' });

  try {
    window.open(
      `${CERTIFICATE.CERTIFICATE_AUTH_URL}?id=${CERTIFICATE.KEY_ID}&nome=${CERTIFICATE.KEY_NAME}&retorno=${CERTIFICATE.CERTIFICATE_LOGIN}`,
      '_self'
    );
  } catch (error) {
    requestError(error);
  } finally {
    dispatch({ type: '@auth/FETCHING_DATA_FINALLY' });
  }
};
